












import { Component, Vue } from 'vue-property-decorator'
import DetailedWeatherWidgetItem from '~components/header/DetailedWeatherWidgetItem.vue'

@Component({
  components: {
    DetailedWeatherWidgetItem,
  },
})
export default class WeatherWidget extends Vue {
  get regionData() {
    return this.$store.getters['weather/weatherData']
  }

  get township() {
    return this.$store.getters['weather/selectedRegionNiceName']
  }

  get hourData() {
    return this.regionData.hourdata || []
  }

  get todayData() {
    if (this.regionData.daydata && this.regionData.daydata.length > 0)
      return this.regionData.daydata[0]
    return null
  }

  onClick() {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('weather/toggleWidget')
  }
}
